.full-width-hero {
  position:relative;
  height:75vw;
  @screen lg {
    height:45vw;
  }

  &.homepage {
    min-height: 425px;
  }

  .image-center {
    padding-bottom:90%;
    img {
      height:100% !important;
      width:auto !important;
      max-width:none;
      min-width:none;
    }
    @screen md {
      padding-bottom:50%;
      img {
        height:auto !important;
        width:100% !important;
        min-width:100%;
      }
    }
  }
  article {
    @apply absolute flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    background: rgba(0,0,0,0.3);
    padding-top: 115px;
    @screen md {
      padding-top:130px;
    }
    h1,
    h4 {
      	text-shadow: 0 2px 4px rgba(0,0,0,0.4);
    }
  }
  &.no-cta {
    height:80vw;
    @screen sm {
      height:60vw;
    }
    @screen lg {
      height:35vw;
    }
    figcaption {
      padding-top: 0;
      @screen md {
        padding-top:0;
      }
    }
  }
  + .block-single-col-copy {
    padding-top: 60px;
  }
}
