.full-width-slider {
  .owl-carousel {
    .owl-item {
      .slide-content > p {
        @apply text-white;
        @extend .font-small;
      }
      .relative {
        position:relative;
        &.overlay-blue {
          &:before {
            content:" ";
            background:rgba(6, 122, 185, 0.6);
            position:absolute;
            top:0;
            right:0;
            bottom:0;
            left:0;
            z-index:9
          }
        }
        figcaption {
          @apply bg-gray-400 text-center;
          width:100%;
          padding-bottom:25px;
          box-sizing: border-box;
          position:relative;
          z-index:10;
          @screen md {
            position:absolute;
            background: rgba(35, 35, 35, 0.8);
            text-align:left;
            width: 45%;
            max-width:960px;
            bottom:30px;
            right: 0;
          }
        }
    }
    }
  }
}
