header {
  background: rgba(61, 61, 61, 0.7);
  position: relative;
  z-index: 12;
}
#main-content {
    margin-top:-115px;
  @screen md  {
    margin-top:-130px;
  }
}
