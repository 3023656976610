.full-width-two-col-cta {
  figure {
    margin: 0;
    background-position: 1000px 1000px;
    background-size: cover;
    background-repeat: no-repeat;
    img {
      width:75%;
      height:auto;
      margin:auto;
      opacity:1;
    }
    .full-width-two-col-cta_color-block {
      position:absolute;
      top:0;
      right:0;
      bottom:0;
      left:0;
      z-index:10;
      opacity:1;
      -webkit-transition: opacity 0.25s ease;
      -moz-transition: opacity 0.25s ease;
      -o-transition: opacity 0.25s ease;
      transition: opacity 0.25s ease;
      transition-timing-function: cubic-bezier(0.22, 0.54, 0.57, 0.82);
      h1 {
        bottom:30px;
        left:0;
        right:0;
        text-align:center;
      }
      &.bg-green {
        background:rgba(187, 207, 42, 0.7);
        &:hover {
          background:rgba(187, 207, 42, 0.8);
        }
      }
      &.bg-orange {
        background:rgba(246, 147, 29, 0.7);
        &:hover {
          background:rgba(246, 147, 29, 0.8);
        }
      }
    }
    .full-width-two-col-cta_button-contain {
      position:absolute;
      align-items: center;
      justify-content: center;
      top:0;
      right:0;
      bottom:0;
      left:0;
      z-index:10;
      display:none;
    }
    &:hover {
      background-position: center center;
      .full-width-two-col-cta_color-block,
      img {
        opacity:0;
      }
      .full-width-two-col-cta_button-contain {
        @apply flex;
      }
    }
  }
}
