//Links
a {
  @apply text-blue-300;
  transition: all .3s ease-in-out;
  text-decoration: underline;

  // &.white {
  //   @apply text-white;
  //   &.hover,
  //   &:hover {
  //     @apply text-blue-200;
  //     text-decoration: none;
  //   }
  //   &.active,
  //   &:active {
  //     @apply text-blue-300;
  //     text-decoration: none;
  //   }
  // }

  &.hover,
  &:hover {
    @apply text-blue-200;
    text-decoration: none;
  }
  &.active,
  &:active {
    @apply text-blue-300;
    text-decoration: none;
  }
  &.disabled,
  &:disabled {

  }
}

p > a {
  font-size: inherit;
  line-height: inherit;
}

//Buttons
.btn {
  @apply rounded font-semibold text-white bg-blue-300 shadow;
  padding: 12px 26px;
  display: inline-block;
  font-size: 19px;
  line-height: 1.3;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  &:hover,
  &.hover {
    @apply shadow-md bg-blue-200 text-white;
  }
  &:active,
  &.active {
    @apply shadow-none bg-gray-200 text-white;
  }
  &.hollow {
    @apply rounded font-semibold text-white bg-transparent border-white border-solid border-2;
    &:hover,
    &.hover {
      @apply bg-white text-gray-400;
    }
    &:active,
    &.active {
      @apply bg-white text-gray-200;
    }
  }
}

//Social Icons
.social-icon {
  @apply text-white bg-accent-orange;
  display: block;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  font-size: 1.25rem;
  text-decoration: none;
  &:hover,
  &.hover {
    @apply text-white;
    background: rgba(246, 147, 29, 0.7);
  }
  &:active,
  &.active {
    @apply text-white bg-gray-200;
  }
  i {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
//Pagination
//
// .pagination {
//   display: flex;
//   align-items: center;
//   a {
//     display: inline-block;
//     margin-right: 1rem;
//   }
//   &-bullet {
//     transition: all .2s ease-in-out;
//     display: inline-block;
//     width: 1rem;
//     height: 1rem;
//     font-size: 0;
//     opacity: .5;
//     &:hover {
//       opacity: .8;
//     }
//     .active & {
//
//       opacity: 1;
//     }
//   }
// }
//
 // Icons
 [class*="icon-"] {
   font-size: inherit;
   //display: flex;
   //align-items: center;
   //justify-content: center;
   //svg {
   //  display: inline-block;
   //  width: 1.35em;
   //  height: 1.35em;
   //  stroke-width: 0;
   //  stroke: currentColor;
   //  fill: currentColor;
   //}
 }
//
// // Social links
// .social-links {
//   a {
//     transition: opacity .3s ease-in-out;
//     &:hover {
//       opacity: .8;
//     }
//   }
// }
//
// // Nav reset
// nav {
//   ul {
//     @apply m-0 p-0;
//     li {
//       @apply m-0 p-0;
//       list-style: none;
//     }
//   }
// }
