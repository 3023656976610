/* MAIN NAVIGATION */
.main-navigation {
  .ts-container {
    height: 75px;
    line-height: 75px;
    padding-top: 0;
    padding-bottom: 0;
    position:relative;
    @screen lg {
      height: 90px;
    }
    figure,
    nav {
      margin:0;
      vertical-align: middle;
      @screen lg {
        box-sizing: border-box;
        width:50% !important;
        float:left;
      }
    }
    figure {
      a {
        @apply flex;
        height:75px;
        vertical-align: middle;
        -webkit-align-items: center;
        -moz-align-items: center;
        -ms-align-items: center;
        -o-align-items: center;
        align-items: center;
        -ms-flex-align: center;
        @screen lg {
          height:90px;
        }
        img {
          height: 65%;
          &:hover {
            opacity:0.7;
          }
        }
      }
    }
    nav {
      @apply bg-gray-400;
      position: fixed;
      z-index:99;
      top: 0;
      bottom: 0;
      left: auto;
      right: -100%;
      margin: 0;
      padding: 115px 30px;
      width: 100%;
      -webkit-transition: right 300ms;
      -moz-transition: right 300ms;
      -o-transition: right 300ms;
      transition: right 300ms;
      overflow-y:scroll;
      &.open {
        right:0;
      }
      @screen lg {
        @apply bg-transparent;
        position:static;
        width: auto;
        padding:0;
        overflow:visible;
      }
      ul {
        text-align:left;
        display:block;
        clear: both;
        margin: 0;
        padding: 0;
        @screen lg {
          text-align:right;
        }
        li {
          margin:0 0 15px 0;
          padding:0 0 15px 0;
          border-bottom:1px solid #3d3d3d;
          display:block;
          position:relative;
          @screen lg {
            display:inline-block;
            margin:0 0 0 17px;
            padding:0;
            border-bottom: 0;
            height: 90px;
            line-height: 90px;
            &.lg\:hidden {
              display: none;
            }
          }

          &.has-children {
            & > a {
              @apply text-gray-200;
              cursor: default;
              &:hover {
                @apply text-gray-200;
              }
              @screen sm {
                color:#fff;
                &:hover {
                  color:#fff;
                }
              }
            }
          }
          .icon-down-arrow,
          .fas {
            display: none;
            line-height: 1;
            //height: 30px;
            padding: 0;
            //margin: -3px 0 2px 0;
            font-size: .85rem;
            text-align: center;
            vertical-align: middle;
            @screen lg {
              display: inline-block;
            }
          }
          a {
            @apply text-white font-semibold uppercase no-underline;
            &.hover,
            &:hover {
              @apply text-blue-200;
              text-decoration: none;
            }
            &.active,
            &:active {
              @apply text-blue-300;
              text-decoration: none;
            }
          }
          .nav-sub-menu {
            display: block;
            position: static;
            margin: 0;
            padding: 8px 0 0 0;
            width: 100%;
            background-color: transparent;
            @screen lg {
              display: block;
              position: absolute;
              margin: 0 0 0 -15px;
              padding: 15px 0;
              width: 150px;
              background-color: rgba(61, 61, 61, 0.9);
              border-bottom: 5px;
              @apply border-blue-200 border-solid;
              display:none;
            }
            li {
              display: block;
              margin: 0;
              text-align: left;
              padding: 0 15px;
              line-height: 30px;
              height: 30px;
              border-bottom:0px;
            }
          }
          &:hover {
            .nav-sub-menu {
              display:block;
            }
          }
        }
      }
    }
    .menu-toggle {
      @apply bg-transparent;
      display: block;
      padding: 0;
      margin: 0;
      border: 0px;
      position: absolute;
      bottom: 23px;
      right: 15px;
      z-index: 999;
      div {
        @apply text-white font-semibold;
        display:inline-block;
        vertical-align: middle;
        &.text {
          &:before {
            @apply text-white;
            content:"MENU";
            font-size:19px;
          }
        }
        &.stripes {
          width:20px;
          margin-left:3px;
          span {
            @apply bg-white;
            border-radius: 5px;
            display:block;
            height:3px;
            margin:3px 0;
            -webkit-transition: all 300ms;
            -moz-transition: all 300ms;
            -o-transition: all 300ms;
            transition: all 300ms;
          }
        }
      }
      &.nav-open {
        position: fixed;
        top: 58px;
        right: 15px;
        height: 40px;
        div {
          &.text {
            &:before {
              content:"CLOSE";
            }
          }
          &.stripes {
            span:first-child {
              -webkit-transform: rotate3d(0, 0, 1, 45deg) translate3d(4px, 5px, 0);
              transform: rotate3d(0, 0, 1, 45deg) translate3d(4px, 5px, 0);
            }
            span:nth-child(2) {
                -webkit-transform: translate3d(200%, 0, 0);
                transform: translate3d(200%, 0, 0);
                opacity: 0;
            }
            span:last-child {
              -webkit-transform: rotate3d(0, 0, 1, -45deg) translate3d(4px, -5px, 0);
              transform: rotate3d(0, 0, 1, -45deg) translate3d(4px, -5px, 0);
            }
          }
        }
      }
      @screen lg {
        display:none;
      }
    }
  }
}

/* TOP NAV */
.top-navigation {
  @apply list-none;
  background:rgba(0,0,0,0.25);
  height:40px;
  margin:0;
  padding:0;
  li {
    @apply text-center;
    display:inline-block;
    width:50%;
    margin:0 -2px;
    &:first-child {
      	border-right: 1px solid rgba(0,0,0,0.25);
    }
    a {
      @apply text-white font-semibold flex items-center justify-center;
      text-decoration:none;
      //display:block;
      height:40px;
      line-height: 40px;
      &:hover {
        background: rgba(0,0,0,0.75);
      }
    }
    .icon-arrow-left {
      margin-right: 10px;
    }
    .icon-arrow-right {
      margin-left: 10px;
    }
  }
}
/* SLIDER NAV */
.slider-nav {
  list-style:none;
  margin:0;
  padding:0;
  li {
    @apply text-blue-300 font-semibold;
    display:inline-block;
    font-size:16px;
    cursor:pointer;
    &:first-child {
      margin-right:15px;
    }
    &:last-child {
      margin-left:15px;
    }
    &:first-child,
    &:last-child {
      &:hover,
      &.hover {
        @apply text-accent-blue;
      }
      &:active,
      &.active {
        @apply text-blue-200;
      }
    }
  }
  &.white {
    li {
      @apply text-white;
    }
  }
}
