.ts-container {
  width:100%;
  max-width:1070px;
  margin:auto;
  padding:30px;
  box-sizing:border-box;
  @media(max-width:600px) {
    padding:15px;
  }
}
