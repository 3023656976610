.team-listing {
  overflow: hidden;
  .team-listing__title {
    h2 {
      font-size:45px;
    }
    h4 {
      font-size:22px;
    }
    padding-top: 50px;
    @screen md {
      padding-top: 40px;
    }
    @screen xxl {
      padding-top: 70px;
    }
  }
  .team-listing__button {
      margin-top:25px;
  }
  .team-listing__image picture {
    @apply relative;
    display: block;
    overflow: hidden;
    min-height: 360px;
    padding-bottom: 0;
    @screen md {
      min-height: 300px;
    }
    @screen xxl {
      min-height: 380px;
    }
    img {
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      object-position: center center;
      font-family: 'object-fit: cover;';
      display: block;
    }
    &::before {
      top: 0;
      left: 0;
      right: 0;
      z-index: 2;
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 0;
      border-top: 72px solid white;
      border-left: 50vw solid transparent;
      border-right: 50vw solid transparent;
      @screen md {
        border-left: 60px solid white;
        border-top: 150px solid transparent;
        border-bottom: 150px solid transparent;
        border-right: 0;
        right: auto;
      }
      @screen xxl {
        border-top: 190px solid transparent;
        border-bottom: 190px solid transparent;
      }
    }
  }
  &:nth-of-type(even) {
    .team-listing__title {
      order: 2;
    }
    .team-listing__image picture {
      order: 1;
      &::before {
        @screen md {
          right: 0;
          left: auto;
          border-right: 60px solid white;
          border-top: 150px solid transparent;
          border-bottom: 150px solid transparent;
          border-left: 0;
        }
        @screen xxl {
          border-top: 190px solid transparent;
          border-bottom: 190px solid transparent;
        }
      }
    }
  }
}
