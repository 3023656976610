.block-pagination-cta {
  padding: 60px 0 0;
  &.has-button {
    padding: 90px 0 100px;
  }
  p {
    @apply pt-sm;
  }
  .single-col-copy__heading + .single-col-copy__sub-heading {
    @apply mt-sm relative;
    margin-bottom: 30px;
    @screen lg {
      margin-bottom: 35px;
    }
  }
    .single-col-copy__heading + p {
    @apply mt-sm;
  }
}

.levels {
  a {
    text-decoration: none;
    font-size: 28px;
    .level {
      height: 60px;
      width: 60px;
      border: 4px solid #3d3d3d;
      line-height: 49px;
      &:hover {
         border: 4px solid #239DE2;
      }
      &.active {
        @apply bg-blue-200 text-white
      }
    }
  }
}

.level-button {
  i {
      font-size: .9rem;
      padding-left: .5rem;
    }
}