html {
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body,
button,
input,
select,
textarea {
  @apply font-sans;
  @apply leading-relaxed;
  @apply text-gray-400;
}
p,
li {
  @apply font-sans;
  @apply leading-relaxed;
  @apply text-gray-400;
  font-size:17px;
  @screen lg {
    font-size: 19px;
  }
}
.font-small {
  @apply leading-normal;
  font-size:14px;
  @screen lg {
    font-size: 16px;
  }
}
ul {
  margin:0;
  padding: 0 0 0 20px;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-sans;
  @apply font-semibold;
  @apply leading-tight;
  margin:0;
}

h1 {
  font-size:48px;
  @screen md {
    font-size: 55px;
  }
  @screen lg {
    font-size: 65px;
  }
}
h2 {
  font-size:30px;
  @screen md {
    font-size: 36px;
  }
  @screen lg {
    font-size: 42px;
  }
}
h3 {
  font-size:26px;
  @screen md {
    font-size: 32px;
  }
  @screen lg {
    font-size: 36px;
  }
}
h4 {
  font-size:22px;
  @screen md {
    font-size: 24px;
  }
  @screen lg {
    font-size: 28px;
  }
}
h5 {
  font-size:19px;
  @apply leading-snug;
  @screen md {
    font-size: 22px;
  }
}
h6 {
  font-size:16px;
  @apply leading-snug;
  @screen md {
    font-size: 19px;
  }
}
.headline-blue-centered {
  text-align:center;
  position:relative;
  &:after {
    content:" ";
    height:5px;
    width:85px;
    position:absolute;
    bottom:0;
    left:0;
    right:0;
    margin:auto;
    @apply bg-blue-200;
  }
  h5 {
    padding: 7px 0 20px 0;
  }
}
.headline-shadow {
  text-shadow: 0 2px 4px rgba(0,0,0,0.4);
}
// .h1,
// .h2,
// h1,
// h2 {
//   @apply font-bold;
// }
//
// .h3,
// .h4,
// .h5,
// .h6,
// h3,
// h4,
// h5,
// h6 {
//   @apply font-bold;
// }
//
// .h2,
// .h3,
// .h4,
// .h5,
// .h6,
// h2,
// h3,
// h4,
// h5,
// h6 {
//   line-height:1;
//   margin:0;
// }
//
// //individual headers
// .h1,
// h1 {
//   @apply text-3xl;
//   margin-bottom: .25em;
//
//   @screen lg {
//     @apply text-5xl;
//   }
// }
//
// .h2,
// h2 {
//   @apply text-2xl leading-tight;
//
//   @screen sm {
//     @apply text-4xl;
//   }
//
//   @screen lg {
//     @apply text-5xl;
//   }
// }
//
// .h3,
// h3 {
//   @apply text-xl leading-tight;
//
//   @screen sm {
//     @apply text-3xl;
//   }
//   @screen lg {
//     @apply text-5xl;
//   }
// }
//
// .h4,
// h4 {
//   @apply text-lg leading-normal;
//
//   @screen sm {
//     font-size: 1.625rem;
//   }
//
//   @screen lg {
//     @apply text-3xl;
//   }
//
// }
//
// .h5,
// h5 {
//   @apply text-lg leading-normal;
//
//   @screen lg {
//     @apply text-xl;
//   }
// }
//
// .h6,
// h6 {
//   @apply text-base leading-tight;
// }
//
// .intro {
//   font-size: 1.05rem;
//   line-height: 1.5em;
//
//   @screen md {
//
//     font-size: 1.25rem;
//     line-height: 1.5em;
//
//   }
//
//   @screen xl {
//     font-size: 1.6rem;
//     line-height: 1.5em;
//   }
//
// }
//
// %small,
// .small {
//   @apply text-sm leading-normal;
//
//   @screen md {
//     @apply text-base;
//   }
//
//   @screen lg {
//     font-size: 1rem;
//   }
//
// }
//
// .sub-head {
//   @apply font-normal font-sans;
//   text-transform: uppercase;
//   font-size: .85rem;
//   line-height: 1.3em;
//   @screen md {
//     font-size: 1rem;
//   }
// }
//
// .label {
//   @apply font-bold;
//   font-size: .8rem;
//   line-height: 1.3em;
//   text-transform: uppercase;
//   @screen md {
//     font-size: .85rem;
//     line-height: 1.3em;
//   }
// }
