.block-column-text-media {
  padding: 60px 0;
    &.bg-waveImage {
      background-image: url("../assets/graphic_home_background_heartbeat.png");
      background-repeat: no-repeat;
      background-size: 200%;
      background-position: 0 25%;
      position: relative;
      @screen md {
        background-size: auto;
        background-repeat: repeat-x;
        background-position: center center;
      }
      &::after {
        z-index: 0;
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(#ffffff, .5);
      }
      > .row {
        position: relative;
        z-index: 1;
      }
    }

    &__text {
      * + p {
        @apply mt-md;
      }
    }
    &__video-link {
      i {
        display: block;
        left:0;
        right:0;
        top:0;
        bottom:0;
        margin:auto;
        width:75px;
        height:75px;
        .icon-button-video-play {
          font-size: 75px;
        }
        img {
          width: 75px;
        }
      }
    }
  .row {
    &.align-media-left {
      flex-direction:row-reverse;
    }
    &.items-top {
      .block-column-text-media__img--wrapper {
        margin-top:5px;
      }
    }
    .block-column-text-media__text {
      h1,h2,h3,h4,h5 {
        @apply mb-sm;
      }
      h5 {
        @apply text-blue-200
      }
    }
  }
  &+.block-column-text-media {
    padding-top: 0;
  }
}
.featherlight .featherlight-content {
    max-width: calc(100% - 30px);
}
.video-lightbox {
  display: none;
  width: 700px;
  max-width: 100%;
}
.videoWrapper {
  display:block;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
