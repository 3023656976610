.block-career-path {
  padding: 60px 15px;
  position:relative;
  @screen md {
    padding: 150px 0;
  }
  &:after {
    content: " ";
    background: url(../assets/bg_images/background_graphic_careers_tree_right.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center right;
    position: absolute;
    top:0;
    right:0;
    left:0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index:1;
    @screen md {
      background-size: contain;
    }
    @screen lg {
      left:auto;
    }
  }
  &:before {
    content: " ";
    background: url(../assets/bg_images/background_graphic_careers_tree_left@2x.png);
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 25%;
    height: 80%;
    z-index:1;
    display:none;
    @screen lg {
      display:block;
    }
  }
  div {
    z-index:2;
    position:relative;
    .icon {
      align-items: center;
      justify-content: center;
      font-size: 78px;
      margin: 0 15px 0 0;
    }
    &.paths {
      padding:0;
      .icon {
        font-size: 22px;
        vertical-align: middle;
        display: inline-block;
        margin: -5px 5px 0 0;
      }
      .icon-arrow-right {
        font-size:15px;
      }
    }
  }
}
