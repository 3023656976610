.block-perks-grid {
  padding: 85px 30px;
  @screen md {
    padding: 100px 0;
  }

  + .block-perks-grid {
    padding-top: 0;
  }

  .perks-grid__perks.row .col {
    padding: 3.5px 0;
    @screen md {
      padding: 15px 7px;
    }
  }

  .perks-grid__perks.row + .perks-grid__perks.row {
    margin-top: 23px;
    @screen md {
      margin-top: 0;
    }
  }

  .perks-grid__subheading p {
    font-size: 18px;
    @screen md {
      font-size: 19px;
    }
  }

  [class*='icon-'] {
    font-size: 1.9rem;
    @screen md {
      font-size: 3.75rem;
    }
  }
}