/* Hero Block */
.hero {
  @apply .flex .relative .overflow-hidden .bg-top .bg-no-repeat .bg-cover .w-full;

  .hero__caption {
    @apply .relative .w-full .px-gutter;
  }

  .hero__heading {}
  .hero__copy {}

}
