.block-two-columns-cta {
  margin-bottom: 50px;
  .cta {
    margin: 50px 0;
    .icon {
      max-width: 51px;
    }
    @screen sm {
      padding: 0 120px;
    }
  }
  h6 {
    font-size: 19px;
  }
}