.team-quotes {
  padding-top:75px;
  padding-bottom:75px;
  article {
    text-align:left;
  }
  .desktop-hide {
    @screen lg {
      display:none;
    }
  }
}
