.three-col-bg-image {
  @apply bg-gray-300;
  position:relative;
  // min-height: 40vw;
  padding: 8vw 0;
  background-size:contain;
  background-position:top;
  background-repeat: no-repeat;
  @screen md {
    background-size:cover;
    background-position:center center;
    background-color:none;
  }
  .ts-container {
    position:relative;
    z-index:10;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
    article {
      img {
        display:block;
        margin:auto;
        height:60px;
        @screen md {
          height: 75px;
        }
      }
      &:first-child {
        @screen md {
          border-right:1px;
          @apply text-gray-200 border-solid border-gray-100;
        }
      }
      &:last-child {
        @screen md {
          border-left:1px;
          @apply text-gray-200 border-solid border-gray-100;
        }
      }
    }
  }
  &:after {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(35, 35, 35, 0.9) 20%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#00000000", endColorstr="rgba(35,35,35,0.9)",GradientType=0 );
    content: " ";
    position: absolute;
    z-index: 5;
    bottom: 0;
    height: 90%;
    width: 100%;
    display: block;
    @screen sm {
      height: 77%;
    }
    @screen md {
      display:none;
    }
  }
}
