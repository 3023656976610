.block-timeline {
  .timeline__entry + .timeline__entry {
    .col  {
      @apply pt-0;
    }
    .row + .row {
      @apply mt-xl;
    }
  }
  .timeline__entry--year {
    display: inline-block;
    @screen sm {
      @apply block;
    }
    @media(max-width: 500px) {
      font-size:18px;
    }
    &::after {
      @apply w-full border-accent-orange border-b-2 absolute;
      transform: translate3d(100%, -50%,0);
      right: 15px;
      top: 50%;
      content: '';
      @screen sm {
        right: -7.5px;
      }
      @screen md {
        right: -15px;
      }
    }
  }
}
