.team-profile-highlight {
  padding-top: 75px;
  padding-bottom: 75px;
  background-image: url('/assets/images/background_leaf_logo_black.png');
  background-repeat: no-repeat;
  background-position: right bottom;
  position:relative;
  background-size: contain;
  .row {
    position:relative;
    z-index:10;
    figure {
      margin:0;
      position:relative;
      z-index:10;
      .image-center {
        @apply shadow;
        padding-bottom:100%;
        border: 5px solid #fff;
        img {
          height:100% !important;
          width:auto !important;
          max-width:none;
        }
      }

    }
    h2 {
      margin-top:10px;
      @screen sm {
        margin-top:-7px;
      }
      @screen md {
        margin-top:-10px;
      }
    }
  }
}
