.sg-colors {
  span {
    display:inline-block;
    width:60px;
    height:60px;
    border-radius:100%;
    margin:0 -2px;
  }
  ul {
    display:inline-block;
    width:70%;
    margin:0 -2px;
  }
}
