@font-face {
  font-family: 'icomoon';
  src:  url('../icomoon/fonts/icomoon.eot?wujzve');
  src:  url('../icomoon/fonts/icomoon.eot?wujzve#iefix') format('embedded-opentype'),
    url('../icomoon/fonts/icomoon.ttf?wujzve') format('truetype'),
    url('../icomoon/fonts/icomoon.woff?wujzve') format('woff'),
    url('../icomoon/fonts/icomoon.svg?wujzve#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-checkmark:before {
  content: "\e945";
}
.icon-life-enrichment:before {
  content: "\e944";
}
.icon-careers-business:before {
  content: "\e93e";
}
.icon-careers-dining-back:before {
  content: "\e93f";
}
.icon-careers-dining-front:before {
  content: "\e940";
}
.icon-careers-health:before {
  content: "\e941";
}
.icon-icon-career-path:before {
  content: "\e942";
}
.icon-careers-perks-assistance:before {
  content: "\e943";
}
.icon-401k-roth:before {
  content: "\e91f";
}
.icon-accident:before {
  content: "\e920";
}
.icon-certifications:before {
  content: "\e921";
}
.icon-chiropractor-visits:before {
  content: "\e922";
}
.icon-columbia-employee-store-discounts:before {
  content: "\e923";
}
.icon-critical-illness:before {
  content: "\e924";
}
.icon-dental-insurance:before {
  content: "\e925";
}
.icon-discounted-employee-meals:before {
  content: "\e926";
}
.icon-employee-assistance-program-eap:before {
  content: "\e927";
}
.icon-flexible-savings-account:before {
  content: "\e928";
}
.icon-free-beverages:before {
  content: "\e929";
}
.icon-free-parking:before {
  content: "\e92b";
}
.icon-hands-on-learning:before {
  content: "\e92c";
}
.icon-health-express:before {
  content: "\e92d";
}
.icon-holidays:before {
  content: "\e92e";
}
.icon-life-accidental-death-and-dismemberment:before {
  content: "\e92f";
}
.icon-medical-insurance:before {
  content: "\e930";
}
.icon-online-learning:before {
  content: "\e931";
}
.icon-pay-active:before {
  content: "\e932";
}
.icon-perkspot:before {
  content: "\e933";
}
.icon-professional-development:before {
  content: "\e934";
}
.icon-sabbatical:before {
  content: "\e935";
}
.icon-scholarships:before {
  content: "\e936";
}
.icon-shoes-for-crews:before {
  content: "\e937";
}
.icon-short-term-disability:before {
  content: "\e938";
}
.icon-sick-time:before {
  content: "\e939";
}
.icon-standing-desks:before {
  content: "\e93a";
}
.icon-vacation:before {
  content: "\e93b";
}
.icon-vision-insurance:before {
  content: "\e93c";
}
.icon-wellness-programs:before {
  content: "\e93d";
}
.icon-arrow_down:before {
  content: "\e92a";
}
.icon-down-arrow:before {
  content: "\e91e";
}
.icon-arrow-left:before {
  content: "\e91d";
}
.icon-button-video-play:before {
  content: "\e900";
}
.icon-accessability:before {
  content: "\e901";
}
.icon-arrow-right:before {
  content: "\e902";
}
.icon-assisted-living:before {
  content: "\e903";
}
.icon-button-email:before {
  content: "\e904";
}
.icon-calendar:before {
  content: "\e905";
}
.icon-close-x:before {
  content: "\e906";
}
.icon-email:before {
  content: "\e907";
}
.icon-equal:before {
  content: "\e908";
}
.icon-expand:before {
  content: "\e909";
}
.icon-floorplan:before {
  content: "\e90a";
}
.icon-future:before {
  content: "\e90b";
}
.icon-health:before {
  content: "\e90c";
}
.icon-house:before {
  content: "\e90d";
}
.icon-independent-living:before {
  content: "\e90e";
}
.icon-memory-care:before {
  content: "\e90f";
}
.icon-open:before {
  content: "\e910";
}
.icon-pdf-download:before {
  content: "\e911";
}
.icon-phone:before {
  content: "\e912";
}
.icon-region:before {
  content: "\e913";
}
.icon-resource-articles:before {
  content: "\e914";
}
.icon-respite-care:before {
  content: "\e915";
}
.icon-size:before {
  content: "\e916";
}
.icon-social-facebook:before {
  content: "\e917";
}
.icon-social-google:before {
  content: "\e918";
}
.icon-social-linkedin:before {
  content: "\e919";
}
.icon-social-twitter:before {
  content: "\e91a";
}
.icon-social-youtube:before {
  content: "\e91b";
}
.icon-wellness:before {
  content: "\e91c";
}
