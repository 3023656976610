.block-single-col-copy {
  padding: 60px 0 55px 0;
  &.has-button {
    padding: 90px 0 100px;
  }
  p {
    @apply pt-sm;
  }
  .single-col-copy__heading + .single-col-copy__sub-heading {
    @apply mt-sm relative;
    margin-bottom: 30px;
    @screen lg {
      margin-bottom: 35px;
    }

    &::after {
      @apply bg-blue-200;
      transform: translate3d(-50%,-50%,0);
      position: absolute;
      bottom: -20px;
      left: 50%;
      content: '';
      display: block;
      width: 100px;
      height: 6px;
      @screen lg {
        bottom: -30px;
      }
    }
  }
  .single-col-copy__heading + p {
    @apply mt-sm;
  }
}
