.job-listings {
  .search-jobs {
    .select-wrapper {
      position:relative;
      display:block;
      background: url(data:image/svg+xml;utf8,%3Csvg%20id%3D%27down_arrow%27%20xmlns%3D%27http%3A…8L6.5%2C6.4h.7L13%2C1.5A.6.6%2C0%2C0%2C0%2C13.1.7Z%27%2F%3E%3C%2Fsvg%3E%0A) no-repeat right 15px center/20px 20px, linear-gradient(#fff 0%, #F2F2F2 100%);
      border-radius:5px;
      i {
        position: absolute;
        right: 15px;
        top: 0;
        bottom: 0;
        font-size: 16px;
        z-index: 1;
        height: 14px;
        margin: auto;
      }
      select {
		@apply font-semibold;
		appearance: none;
        display: block;
        position:relative;
        z-index:2;
        width: 100%;
        padding: 0 10px;
        box-shadow: none;
        background:transparent;
        height: 54px;
        border:0;
		font-size:18px;
		&::-ms-expand {
			display: none;
		}
      }
    }
  }
	.box {
		border-bottom: 4px solid;
		@apply border-blue-200 relative;
		transition: all 0.3s ease;
		.open,
    .close {
			position: absolute;
      top:15px;
      right: 20px;
      font-size:18px;
      text-transform: uppercase;
      i {
        &:before {
          vertical-align: middle;
        }
      }
		}
    .open {
        i {
          &:before {
            font-size:22px;
            vertical-align: middle;
            margin-top: -2px;
            display: inline-block;
          }
        }
    }
	}
	.single-location {
    .job-details {
      border-bottom: 2px solid #fff;
      padding: 15px 0;
      &:last-child {
        border-bottom:0;
        padding-bottom:0;
      }
      .row {
        @media(max-width:720px) {
          padding:0;
          margin:0;
        }
      }
    }
    .btn {
      i {
        vertical-align: middle;
        margin-top: -4px;
        display: inline-block;
      }
    }
		.expand-group {
			cursor: pointer;
			display: block;
		}
		.open {
			display: inline-block;
		}
		.close {
			display: none;
		}
		.expanded {
			.close {
				display: inline-block;
			}
			.open {
				display: none;
			}
		}
		h3 {
			i {
				background: blue;
				width: 40px;
				height: 40px;
				color: #fff;
				display: block;
				text-align: center;
				line-height: 40px;
				float: left;
				margin-right: 10px;
				border-radius: 100%;
				font-size: 2rem;
			}
		}
	}
	.job-listings {
		background-color: #eee;
		padding-bottom: 100px;
	}
	.job-listings {
		padding-top: 50px;
		padding-bottom: 50px;
		.job-select,
		.location-select,
		.find-button {
			display: inline-block;
			padding-right: 20px;
		}
		.job-select {
			display: block;
			padding-right: 0;
			@screen-md {
				padding-right: 20px;
				width: 50%;
				display: inline-block;
			}
		}
		.location-select {
			@screen-md {
				width: 49%;
			}
		}
		.find-button {
			padding-right: 0;
			@screen-md {
				width: 9%;
			}
		}
		.form {
			border: 0;
			margin-top: -30px;
			margin-bottom: 30px;
			input,
			select,
			textarea {
				margin-top: 8px;
				margin-bottom: 25px;
				height: 54px;
			}
		}
		h2 {
			text-align: center;
			padding: 20px 0;
			&:first-of-type {
				padding-top: 0;
			}
		}
		[class^='col-'] {
			margin-bottom: 0;
		}
		.floor-plan-row {
			border-bottom: 2px solid yellow;
			padding: 10px 0;
			h3 {
				padding-top: 10px;
			}
			.btn-column {
				display: flex;
				@media (min-width: screen-sm) {
					justify-content: flex-end;
					flex-direction: column;
				}
			}
			.btn {
				float: none;
				margin: 10px 10px 10px 0;
				padding: 10px 24px;
				font-size: 1.6rem;
				text-align: center;
				i {
					font-size: 1.1rem;
					padding-left: 8px;
					vertical-align: middle;
				}
				@media (min-width: screen-sm) {
					float: right;
					margin-right: 0;
					padding: 10px 0;
				}
			}
			.modal-content {
				max-width: 600px;
				margin-top: 40px;
				text-align: center;
				max-height: calc(100vh - 80px);
				img {
					max-width: calc(95% - 40px);
				}
			}
			.modal {
				.modal-close {
					top: -40px;
				}
			}
			@media (min-width: screen-sm) {
				align-items: center;
				display: flex;
			}
		}
		ul {
			padding: 10px 0 0;
			list-style: none;
			margin: 4px 0;
			display: block;
		}
		li {
			display: inline-block;
			font-size: 1.6rem;
			line-height: 30px;
			padding: 0 20px 10px 0;
			i {
				background: blue;
				width: 30px;
				height: 30px;
				color: #fff;
				display: block;
				text-align: center;
				line-height: 30px;
				float: left;
				margin-right: 10px;
				border-radius: 100%;
				font-size: 1.7rem;
			}
		}
	}
	.job-listings {
		background-color: #eee;
		padding-bottom: 100px;
		padding-top: 0;
	}
}
