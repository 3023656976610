footer {
  @apply bg-gray-400;
  padding:30px 0 0 0;
  .ts-container {
    padding-bottom:12px;
    .logo {
      display:block;
      margin:0;
      img {
        height: 48.75px;
        @screen lg {
          height:58.45px;
        }
      }
    }
    .footer_contact-icons {
      li {
        span {
          display:inline-block;
          font-size: 1.25rem;
          margin-right:5px;
          vertical-align:middle;
          i {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
    .social-icon {
      font-size: 1.25rem;
      text-decoration: none;
      i {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    h6 {
      border-bottom:2px;
      @apply text-gray-200 border-solid border-gray-300;
      &.lead {
        @apply flex;
        height:auto;
        vertical-align: bottom;
        -webkit-align-items: flex-end;
        -moz-align-items: flex-end;
        -ms-align-items: flex-end;
        -o-align-items: flex-end;
        align-items: flex-end;
        @screen lg {
          height:100px;
        }
      }
    }
    .footer_bottom {
      //test
      li {
        padding: 0 10px;
        line-height: 1;
        border-right: 1px;
        @apply border-solid border-white;
        &:first-child {
          padding-left:0;
        }
        &:last-child {
          border-right:0;
          padding-right:0;
        }
      }
    }
  }
}
