.block-two-col-hero {
  background-size: cover;
  background-repeat: no-repeat;
  .inner {
    padding: 60px 0;
    background-color: rgba(#232323, .8);
    @screen md {
      padding: 100px 15px;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: white;
  }
  * + p {
    margin-top: 12px;
  }
}