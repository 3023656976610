//column and row styles

.row {
  @apply flex flex-wrap flex-grow  max-w-row mx-auto px-gutter;

  @screen md {
    @apply .px-gutter-tablet;

    .row {
      @apply .px-0;
    }
  }

  @screen lg {
    @apply px-gutter-desktop;
  }

  @screen row {
    @apply .px-0;

    .row {
      @apply .px-0;
    }
  }

  .col {
    @apply px-gutter;
    @screen md {
      @apply px-gutter-tablet;
    }
  }

  &.full {
    @apply .max-w-full;
  }

  &.narrow {
    @apply .justify-center;

    @screen md {
      .col.w-full:not([class*='sm:w']):not([class*='md:w']):not([class*='lg:w']):not([class*='xl:w']) {
        @apply w-full max-w-lg;

        .row & {
          @apply w-full;
        }
      }
    }

    @screen lg {
      .col.w-full:not([class*='sm:w']):not([class*='md:w']):not([class*='lg:w']):not([class*='xl:w']) {
        @apply .w-2/3;
        max-width: unset;

        .row & {
          @apply .w-full;
        }
      }
    }
  }

  &.collapse {
    .col {
      @apply .px-0;
    }
  }

  // row within a row should have outer gutters removed
  .row {
    .col {
      @apply .p-0;

      &[class*='w-']:not([class*='w-full']) {
        @apply .px-gutter;

        &:first-child { @apply .pl-0; }
        &:last-child { @apply .pr-0; }
      }


      @screen sm {
        &[class*='sm:w']:not([class*='sm:w-full']) {
          @apply .px-gutter;

          &:first-child { @apply .pl-0; }
          &:last-child { @apply .pr-0; }
        }
      }

      @screen md {
        &[class*='md:w']:not([class*='md:w-full']) {
          @apply .px-gutter;

          &:first-child { @apply .pl-0; }
          &:last-child { @apply .pr-0; }
        }
      }

      @screen lg {
        &[class*='lg:w']:not([class*='lg:w-full']) {
          @apply .px-gutter;

          &:first-child { @apply .pl-0; }
          &:last-child { @apply .pr-0; }
        }
      }

      @screen row {
        &[class*='row:w']:not([class*='row:w-full']) {
          @apply .px-gutter-desktop;

          &:first-child { @apply .pl-0; }
          &:last-child { @apply .pr-0; }
        }
      }

      @screen xl {
        &[class*='xl:w']:not([class*='xl:w-full']) {
          @apply .px-gutter;

          &:first-child { @apply .pl-0; }
          &:last-child { @apply .pr-0; }
        }
      }

      // @screen xxl {
      //   &[class*='xxl:w']:not([class*='xxl:w-full']) {
      //     @apply .px-gutter;
      //
      //     &:first-child { @apply .pl-0; }
      //     &:last-child { @apply .pr-0; }
      //   }
      // }

    }
  }
}

.col {
  @apply .px-gutter .w-full;

  @screen row {
    @apply .px-gutter-desktop;
  }
}
